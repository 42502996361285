.submit-button {
  width: 100%;
  height: 100%;
  background-color: #ff970a;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 12px 0px #ff970a;
  -moz-box-shadow: 0px 2px 12px 0px #ff970a;
  box-shadow: 0px 2px 12px 0px #ff970a;
  .submit-button-text {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    font-family: 'montserrat-bold';
  }
  .submit-button-text-disabled {
    color: #616161;
    font-weight: bold;
  }
}

.submit-button-disabled {
  background-color: #e0e0e0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

