.selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testText {
  color: #3c3c3c;
  font-size: 14px;
  width: 85%;
  font-family: montserrat-semibold;
}

.addButton {
  border: 1px solid #01b37a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 64px;
  cursor: pointer;
}

.addText {
  font-size: 14px;
  color: #01b37a;
  font-family: montserrat-semibold;
}

.addedButton {
  border: 1px solid #01b37a;
  border-radius: 8px;
  background-color: #01b37a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 64px;
  cursor: pointer;
}

.addedText {
  font-size: 14px;
  color: #fff;
  font-family: montserrat-semibold;
}
.category {
  color: #616161;
  font-size: 12px;
  font-family: montserrat-medium;
  display: flex;
  align-items: center;
}
.reportTimeIcon {
  height: 20px;
  width: 20px;
  padding-right: 4px;
}
.rupee {
  color: #3c3c3c;
  font-size: 14px;
  font-family: montserrat-semibold;
  padding-top: 2px;
}
.amount {
  font-size: 16px;
  font-family: montserrat-semibold;
  color: #3c3c3c;
}
.textRow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textRowPrice {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7px;
}
.testMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.divider {
  background-color: #f1f1f1;
  padding-top: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.groupTestsList {
  font-size: 12px;
  line-height: 18px;
  color: #616161;
  font-family: montserrat-semibold;
}
.ViewTestscontainer {
  margin: auto auto;
}
.groupTestText {
  font-size: 12px;
  color: #616161;
  font-family: montserrat-medium;
}
.viewAllTestLayout {
  display: flex;
  align-items: center;
}
.viewAllTestsContainer {
  cursor: pointer;
  margin-top: 3px;
  margin-left: 24px;
  display: flex;
  align-items: center;
}
.viewAllTests {
  font-size: 10px;
  color: #ff970a;
  font-family: montserrat-semibold;
}
.arrow {
  height: 20px;
  width: 20px;
  padding-right: 4px;
}
.testArrow {
  height: 16px;
  width: 16px;
  padding-right: 4px;
}
.row {
  display: flex;
  align-items: flex-start;
}
.row:after {
  content: '';
  display: flex;
  align-items: center;
  clear: both;
}
.col-1 {
  float: left;
  width: 6%;
}
.col-2 {
  float: left;
  width: 94%;
}
.note {
  margin-top: 16px;
}
.fastText {
  font-size: 12px;
  color: #616161;
  font-family: montserrat-medium;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.modalTitle {
  font-family: montserrat-semibold;
  font-size: 14px;
}
.closeBtnWrapper {
  cursor: pointer;
}
.closeBtnIcon {
  width: 24px;
  height: 24px;
}
.groupTestsList {
  color: #3c3c3c;
  font-size: 12px;
  margin-bottom: 8px;
  font-family: montserrat-medium;
}
.showPointer{
  cursor: pointer;
}