.loaderWrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 100px;
  height: 100px;
}
.miniLoader {
  width: 70px;
  height: 70px;
}
