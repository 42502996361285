@font-face {
  font-family: 'montserrat-medium';
  font-weight: 'normal';
  src: url('../../static/fonts/montserrat-medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'montserrat-semibold';
  font-weight: 'bold';
  src: url('../../static/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 'bold';
  font-family: 'montserrat-bold';
  src: url('../../static/fonts/montserrat-bold.ttf') format('truetype');
  font-display: swap;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  letter-spacing: 0px;
}

body, input {
  font-family: 'montserrat-medium';
}

input {
  outline: none;
}

html,
body,
#root,
.routes
{
  width: 100%;
  height: 100%;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
}

.common-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
  overflow: auto;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.ReactModal__Body--open {
  overflow-y: hidden;
}
