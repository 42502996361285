.app-text {
    word-break: break-word;
    font-family: 'montserrat-medium';
    text-overflow: ellipsis;
    overflow: hidden;
    div {
        width: auto;
    }
    display:inherit;
}

.inline {
    display: inline;
}
